import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../../assets/images/Logo-fotomanager.png';

const DashboardHeader = ({ menuHandler }) => (
  <header className="dashboard-header">
    <div className="dashboard-header__logo">
      <img src={Logo} alt="" />
      <button
        className="dashboard-header__menu-button"
        onClick={menuHandler}
        type="button"
      >
        <i className="fas fa-bars" />
      </button>
    </div>
  </header>
);

DashboardHeader.propTypes = {
  menuHandler: PropTypes.func.isRequired,
};

export default DashboardHeader;
