import {
  UPDATE_CAMERA_DATA,
} from '../actions/types';
import initialState from './initialState';

export default function (state = initialState.camera, payload) {
  switch (payload.type) {
    case UPDATE_CAMERA_DATA:
      return {
        ...state,
        cameraId: payload.image.camera.id,
        image: payload.image,
        date: payload.image.created_at,
        project: payload.image.camera.project,
      };
    default:
      return state;
  }
}
