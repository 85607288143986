import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
} from '../actions/types';
import initialState from './initialState';

export default function (state = initialState.user, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.user,
        token: action.token,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.error,
      };
    default:
      return state;
  }
}
