import {
  MIN_MAX_DATE,
} from '../actions/types';
import initialState from './initialState';

export default function (state = initialState.dates, action) {
  switch (action.type) {
    case MIN_MAX_DATE:
      return {
        min: action.dates[0].date,
        max: action.dates[1].date,
      };
    default:
      return state;
  }
}
