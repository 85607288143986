import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Accordion } from 'react-accessible-accordion';
import { Redirect } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import MenuAccordion from './MenuAccordion';
import * as api from '../../api/api';
import Logo from '../../assets/images/mobile-logo.png';

const DashboardMenu = ({
  user, openedMenu, menuHandler, token, updateApp,
}) => {
  const [redirect, setRedirect] = useState(false);

  /**
   * Function to logout
   */
  const logout = async () => {
    await api.post('/api/user/logout', { token })
      .then(
        (reply) => {
          if (!reply.error) {
            setRedirect(true);
          } else {
            NotificationManager.error(reply.message);
          }
        },
        (error) => {
          if (error.message !== 'cancelado') {
            NotificationManager.error('Hubo un error cerrando la sesión.');
          }
        },
      );
  };

  useEffect(() => {
    if (redirect) {
      updateApp('login');
    }
  }, [redirect, updateApp]);

  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to="/login" />;
    }
    return false;
  };

  /**
   * Render List ot items
   */
  const RenderList = () => {
    const { permissions } = user.role;

    let showUsersPanels = false;
    let showProjectsPanels = false;
    let showCamerasPanels = false;
    let showVideosPanels = false;
    let showProfilePanels = false;

    const userLinks = [];
    const projectLinks = [];
    const cameraLinks = [];
    const videosLinks = [];
    const profileLinks = [];

    permissions.forEach((permi) => {
      switch (permi.logical_name) {
        case 'see_all_users':
          showUsersPanels = true;
          userLinks.push({ label: permi.name, link: '/dashboard/users', id: permi.id });
          break;
        case 'create_users':
          showUsersPanels = true;
          userLinks.push({ label: permi.name, link: '/dashboard/user/create', id: permi.id });
          break;
        case 'see_all_projects':
          showProjectsPanels = true;
          projectLinks.push({ label: permi.name, link: '/dashboard/projects', id: permi.id });
          break;
        case 'create_projects':
          showProjectsPanels = true;
          projectLinks.push({ label: permi.name, link: '/dashboard/project/create', id: permi.id });
          break;
        case 'see_cameras':
          showCamerasPanels = true;
          cameraLinks.push({ label: permi.name, link: '/dashboard/cameras', id: permi.id });
          break;
        case 'create_cameras':
          showCamerasPanels = true;
          cameraLinks.push({ label: permi.name, link: '/dashboard/camera/create', id: permi.id });
          break;
        case 'create_videos':
          showVideosPanels = true;
          videosLinks.push({ label: permi.name, link: '/dashboard/video/create', id: permi.id });
          break;
        case 'see_all_videos':
          showVideosPanels = true;
          videosLinks.push({ label: permi.name, link: '/dashboard/videos/all', id: permi.id });
          break;
        case 'see_assigned_cameras':
          showCamerasPanels = true;
          cameraLinks.push({ label: permi.name, link: '/dashboard/cameras', id: permi.id });
          break;
        case 'edit_profile':
          showProfilePanels = true;
          profileLinks.push({ label: permi.name, link: '/dashboard/profile', id: permi.id });
          profileLinks.push({
            label: '', link: '/', id: 'logout', onclick: logout,
          });
          break;
        default:
      }
    });

    return (
      <Accordion allowZeroExpanded>
        {showUsersPanels && <MenuAccordion itemButton="Usuarios" links={userLinks} icon="fa-user" menuHandler={menuHandler} />}
        {showProjectsPanels && <MenuAccordion itemButton="Proyectos" links={projectLinks} icon="fa-building" menuHandler={menuHandler} />}
        {showCamerasPanels && <MenuAccordion itemButton="Cámaras" links={cameraLinks} icon="fa-camera" menuHandler={menuHandler} />}
        {showVideosPanels && <MenuAccordion itemButton="Videos" links={videosLinks} icon="fa-video" menuHandler={menuHandler} />}
        {showProfilePanels && <MenuAccordion itemButton="Perfil" links={profileLinks} icon="fa-user-circle" menuHandler={menuHandler} />}
      </Accordion>
    );
  };

  /**
   * Render all component
   */
  return (
    <nav className={`dashboard-menu ${openedMenu ? 'dashboard-menu--opened' : ''}`}>
      {renderRedirect()}
      <img src={Logo} alt="Fotomanager" className="dashboard-menu__logo" />
      <button
        type="button"
        className="dashboard-menu__close-button"
        onClick={menuHandler}
      >
        <i className="far fa-times-circle" />
      </button>
      {Object.prototype.hasOwnProperty.call(user, 'role') && <RenderList /> }
      <NotificationContainer />
    </nav>
  );
};

DashboardMenu.propTypes = {
  user: PropTypes.objectOf(PropTypes.shape).isRequired,
  token: PropTypes.string.isRequired,
  openedMenu: PropTypes.bool.isRequired,
  menuHandler: PropTypes.func.isRequired,
  updateApp: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user.data,
  token: state.user.token,
});

export default connect(
  mapStateToProps,
)(DashboardMenu);
