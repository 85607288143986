const axios = require('axios');

const { CancelToken } = axios;
let cancel;

const { REACT_APP_API_URL } = process.env;

export const asyncPost = async (endoint, parameters) => {
  const param = Object.entries(parameters).map(e => e.join('=')).join('&');
  const requestOptions = {
    method: 'POST',
    body: param,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  const response = await fetch(`${REACT_APP_API_URL}${endoint}`, requestOptions);
  const data = await response.json();
  return data;
};

/**
 * Function to send data to the server
 */
export const post = (endoint, parameters) => axios({
  method: 'post',
  url: `${REACT_APP_API_URL}${endoint}`,
  data: parameters,
  cancelToken: new CancelToken(((c) => {
    cancel = c;
  })),
}).then(response => response.data);

export const asyncGet = async (endoint, parameters) => {
  const param = {
    params: parameters,
    cancelToken: new CancelToken(((c) => {
      cancel = c;
    })),
  };

  const response = await axios.get(`${REACT_APP_API_URL}${endoint}`, param);
  const data = await response.data;
  return data;
};

/**
 * Function to get data from server
 */
export const get = (endoint, parameters) => {
  const param = {
    params: parameters,
    cancelToken: new CancelToken(((c) => {
      cancel = c;
    })),
  };

  return axios.get(`${REACT_APP_API_URL}${endoint}`, param).then(response => response.data);
};

/**
 * Function to cancel a request to the server
 */
export const cancelRequest = () => {
  cancel('cancelado');
};
