import {
  UPDATE_COMPARATOR_IMAGES,
  UPDATE_FIRST_COMPARATOR_IMAGE,
  UPDATE_LAST_COMPARATOR_IMAGE,
} from '../actions/types';
import initialState from './initialState';

export default function (state = initialState.comparator, payload) {
  switch (payload.type) {
    case UPDATE_COMPARATOR_IMAGES:
      return {
        cameraId: payload.cameraId,
        firstImage: payload.firstImage,
        lastImage: payload.lastImage,
      };
    case UPDATE_FIRST_COMPARATOR_IMAGE:
      return {
        ...state,
        firstImage: payload.image,
      };
    case UPDATE_LAST_COMPARATOR_IMAGE:
      return {
        ...state,
        lastImage: payload.image,
      };
    default:
      return state;
  }
}