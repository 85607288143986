import {
  UPDATE_CAMERA_DATA,
} from './types';

export const updateCameraDataAction = image => ({
  type: UPDATE_CAMERA_DATA,
  image,
});

export default updateCameraDataAction;
