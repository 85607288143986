/**
 * Actions consts for login process
 */
export const LOGIN_SUCCESS = 'GET_ALL_CONTENT_SUCCESS';
export const LOGIN_FAILURE = 'GET_ALL_CONTENT_FAILURE';

/**
 * Action const for menu process
 */
export const MENU_LIST = 'MENU_LIST';

/**
 * Actions consts for Images of comparator
 */
export const UPDATE_FIRST_COMPARATOR_IMAGE = 'UPDATE_FIRST_COMPARATOR_IMAGE';
export const UPDATE_LAST_COMPARATOR_IMAGE = 'UPDATE_LAST_COMPARATOR_IMAGE';
export const UPDATE_COMPARATOR_IMAGES = 'UPDATE_COMPARATOR_IMAGES';

/**
 * Actions consts  for Dates for calendars (max and min)
 */
export const MIN_MAX_DATE = 'MIN_MAX_DATE';

/**
 * Actions consts to update camera data
 */
export const UPDATE_CAMERA_DATA = 'UPDATE_CAMERA_DATA';
