/**
 * This is a root reducer, It controls all reducers
 */

import { combineReducers } from 'redux';
import userReducer from './userReducer';
import cameraReducer from './cameraReducer';
import comparatorReducer from './comparatorReducer';
import datesReducer from './datesReducer';

const rootReducer = combineReducers({
  user: userReducer,
  camera: cameraReducer,
  comparator: comparatorReducer,
  dates: datesReducer,
});

export default rootReducer;
