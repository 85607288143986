import React, {
  lazy,
  createRef,
  Fragment,
  Suspense,
  useState,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const { REACT_APP_API_URL } = process.env;

/**
 * Load components
 */
const Calendar = lazy(() => import('./Calendar'));
const Videos = lazy(() => import('./Videos'));
const SendEmailForm = lazy(() => import('./SendEmailForm'));

const Footer = ({
  cameraId,
  token,
  project,
  image,
  updateApp,
}) => {
  // Refs
  const calendaRef = createRef();
  const videosRef = createRef();

  // Hooks
  const [showFooter, setShowFooter] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);

  // Mobiles validations
  const Small = ({ children }) => {
    const isSmall = useMediaQuery({ maxWidth: 1200 });
    return isSmall ? children : null;
  };

  const isSmall = useMediaQuery({ maxWidth: 1200 });

  const openFooter = () => {
    setShowFooter(true);
  };

  const closeFooter = () => {
    setShowFooter(false);
  };

  const toogleEmailForm = (status) => {
    setShowEmailForm(status);
  };

  return (
    <Fragment>
      <Suspense fallback={<span />}>
        <Calendar
          cameraId={cameraId}
          ref={calendaRef}
          type="mainImage"
        />
        {Object.prototype.hasOwnProperty.call(project, 'id') && (
          <Videos
            cameraId={cameraId}
            token={token}
            project={project}
            ref={videosRef}
          />
        )}
        <SendEmailForm
          image={image}
          token={token}
          showEmailForm={showEmailForm}
          toogleEmailForm={toogleEmailForm}
          updateApp={updateApp}
        />
        <div className={`footer ${(!showFooter && isSmall) ? 'footer--closed' : 'footer--opened'}`}>
          <Small>
            <button
              type="button"
              className="footer__button footer__close-button"
              onClick={closeFooter}
            >
              <i className="fas fa-angle-double-down" />
            </button>
          </Small>
          <button
            type="button"
            onClick={() => calendaRef.current.getWrappedInstance().openCalendar()}
            className="footer__button"
          >
            <i className="far fa-calendar-alt" />
          </button>
          <Link onClick={() => updateApp('comparator')} className="footer__button" to={`/comparator/${cameraId}`}>
            <i className="fas fa-columns" />
          </Link>
          <button
            type="button"
            onClick={() => videosRef.current.openVideos()}
            className="footer__button"
          >
            <i className="far fa-play-circle" />
          </button>
          <a
            className="footer__button"
            href={`${REACT_APP_API_URL}/api${image.original_file}?token=${token}&download=true`}
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            <i className="fas fa-download" />
          </a>
          <button type="button" onClick={() => toogleEmailForm(true)} className="footer__button">
            <i className="fas fa-paper-plane" />
          </button>
        </div>
        <Small>
          <button
            type="button"
            className="footer__open-button"
            onClick={openFooter}
          >
            <i className="fas fa-angle-double-up" />
          </button>
        </Small>
      </Suspense>
    </Fragment>
  );
};

Footer.propTypes = {
  token: PropTypes.string.isRequired,
  image: PropTypes.objectOf(PropTypes.shape).isRequired,
  cameraId: PropTypes.number.isRequired,
  project: PropTypes.objectOf(PropTypes.shape).isRequired,
  updateApp: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  token: state.user.token,
  image: state.camera.image,
  cameraId: state.camera.cameraId,
  project: state.camera.project,
});

export default connect(
  mapStateToProps,
  null,
)(Footer);
