import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { useMediaQuery } from 'react-responsive';

const MenuAccordion = ({
  itemButton, links, icon, menuHandler,
}) => {
  const isSmall = useMediaQuery({ maxWidth: 768 });
  return (
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>
          <i className={`fas ${icon}`} />
          {itemButton}
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <ul>
          {links.map(l => (
            <li key={l.id}>
              {l.id !== 'logout' && (
                <Link
                  to={l.link}
                  onClick={() => {
                    if (isSmall) {
                      menuHandler();
                    }
                  }}
                >
                  <i className="fas fa-arrow-right" />
                  {l.label}
                </Link>
              )}
              {l.id === 'logout' && (
                <button type="button" onClick={l.onclick}>
                  <i className="fas fa-arrow-right" />
                  Cerrar Sesión
                </button>
              )}
            </li>
          ))}
        </ul>
      </AccordionItemPanel>
    </AccordionItem>
  );
};

MenuAccordion.propTypes = {
  itemButton: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape).isRequired,
  icon: PropTypes.string.isRequired,
  menuHandler: PropTypes.func.isRequired,
};

export default MenuAccordion;
