import React, {
  Component,
  lazy,
  Suspense,
  Fragment,
} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import history from './helpers/history';

/**
 * General components
 */
import DashboardHeader from './components/dashboard/DashboardHeader';
import DashboardMenu from './components/dashboard/DashboardMenu';
import Arrows from './components/front/Arrows';
import CameraFooter from './components/front/Footer';

/**
 * Load components by lazy
 */
const Login = lazy(() => import('./components/front/Login'));
const Dashboard = lazy(() => import('./components/dashboard/Dashboard'));
const Cameras = lazy(() => import('./components/dashboard/Cameras'));
const CameraFormContainer = lazy(() => import('./components/dashboard/CameraFormContainer'));
const Camera = lazy(() => import('./components/front/Camera'));
const ImageComparator = lazy(() => import('./components/front/ImageComparator'));
const Users = lazy(() => import('./components/dashboard/Users'));
const UserFormContainer = lazy(() => import('./components/dashboard/UserFormContainer'));
const Projects = lazy(() => import('./components/dashboard/Projects'));
const ProjectFormContainer = lazy(() => import('./components/dashboard/ProjectFormContainer'));
const VideoFormContainer = lazy(() => import('./components/dashboard/VideoFormContainer'));
const Videos = lazy(() => import('./components/dashboard/Videos'));
const ProfileFormContainer = lazy(() => import('./components/dashboard/ProfileFormContainer'));
const ResetPasswordForm = lazy(() => import('./components/front/ResetPasswordForm'));

class App extends Component {
  constructor() {
    super();
    this.state = {
      screen: 'login',
      openedMenu: false,
    };
  }

  updateComponent = (type = '') => {
    let uri = type;
    if (!type) {
      uri = window.location.href;
    }
    if (uri.includes('dashboard')) {
      this.setState({
        screen: 'dashboard',
      });
    } else if (uri.includes('camera')) {
      this.setState({
        screen: 'camera',
      });
    } else if (uri.includes('comparator')) {
      this.setState({
        screen: 'comparator',
      });
    } else if (uri.includes('login')) {
      this.setState({
        screen: 'login',
      });
    } else if (uri.includes('password')) {
      this.setState({
        screen: 'login',
      });
    }
    this.forceUpdate();
  }

  componentDidMount = () => {
    this.updateComponent();
    window.onpopstate = () => {
      this.updateComponent();
    };
  }

  /**
   * Open and close the menu
   */
  menuHandler = () => {
    const { openedMenu } = this.state;
    this.setState({ openedMenu: !openedMenu });
  }

  render() {
    const { screen, openedMenu } = this.state;
    return (
      <Fragment>
        <Router history={history}>
          {screen === 'login' && (
            <Suspense fallback={<span>Cargando...</span>}>
              <Switch>
                <Route
                  path="/login"
                  render={props => (
                    <Login
                      {...props}
                      updateApp={this.updateComponent}
                    />
                  )}
                  exact
                />
                <Route
                  path="/password/reset/:token/:email"
                  render={props => (
                    <ResetPasswordForm
                      {...props}
                      updateApp={this.updateComponent}
                    />
                  )}
                  exact
                />
              </Switch>
            </Suspense>
          )}
          {screen === 'dashboard' && (
            <Fragment>
              <DashboardHeader
                menuHandler={this.menuHandler}
              />
              <section className="dashboard-container">
                <DashboardMenu
                  openedMenu={openedMenu}
                  menuHandler={this.menuHandler}
                  updateApp={this.updateComponent}
                />
                <Suspense fallback={<span>Cargando...</span>}>
                  <Switch>
                    <Route
                      path="/dashboard"
                      render={props => (
                        <Dashboard
                          {...props}
                          updateApp={this.updateComponent}
                        />
                      )}
                      exact
                    />
                    <Route
                      path="/dashboard/cameras"
                      render={props => (
                        <Cameras
                          {...props}
                          updateApp={this.updateComponent}
                        />
                      )}
                      exact
                    />
                    <Route
                      path="/dashboard/camera/create"
                      render={props => (
                        <CameraFormContainer
                          {...props}
                          updateApp={this.updateComponent}
                        />
                      )}
                      exact
                    />
                    <Route
                      path="/dashboard/camera/edit/:id"
                      render={props => (
                        <CameraFormContainer
                          {...props}
                          updateApp={this.updateComponent}
                          edit
                        />
                      )}
                      exact
                    />
                    <Route
                      path="/dashboard/users"
                      render={props => (
                        <Users
                          {...props}
                          updateApp={this.updateComponent}
                        />
                      )}
                      exact
                    />
                    <Route
                      path="/dashboard/user/create"
                      render={props => (
                        <UserFormContainer
                          {...props}
                          updateApp={this.updateComponent}
                        />
                      )}
                      exact
                    />
                    <Route
                      path="/dashboard/user/edit/:id"
                      render={props => (
                        <UserFormContainer
                          {...props}
                          updateApp={this.updateComponent}
                          edit
                        />
                      )}
                      exact
                    />
                    <Route
                      path="/dashboard/projects"
                      render={props => (
                        <Projects
                          {...props}
                          updateApp={this.updateComponent}
                        />
                      )}
                      exact
                    />
                    <Route
                      path="/dashboard/project/create"
                      render={props => (
                        <ProjectFormContainer
                          {...props}
                          updateApp={this.updateComponent}
                        />
                      )}
                      exact
                    />
                    <Route
                      path="/dashboard/project/edit/:id"
                      render={props => (
                        <ProjectFormContainer
                          {...props}
                          updateApp={this.updateComponent}
                          edit
                        />
                      )}
                      exact
                    />
                    <Route
                      path="/dashboard/video/create"
                      render={props => (
                        <VideoFormContainer
                          {...props}
                          updateApp={this.updateComponent}
                        />
                      )}
                      exact
                    />
                    <Route
                      path="/dashboard/videos/all"
                      render={props => (
                        <Videos
                          {...props}
                          updateApp={this.updateComponent}
                        />
                      )}
                      exact
                    />
                    <Route
                      path="/dashboard/video/edit/:id"
                      render={props => (
                        <VideoFormContainer
                          {...props}
                          edit
                          updateApp={this.updateComponent}
                        />
                      )}
                      exact
                    />
                    <Route
                      path="/dashboard/profile"
                      render={props => (
                        <ProfileFormContainer
                          {...props}
                          updateApp={this.updateComponent}
                        />
                      )}
                      exact
                    />
                  </Switch>
                </Suspense>
              </section>
            </Fragment>
          )}
          {screen === 'camera' && (
            <section className="camera">
              <Arrows />
              <Suspense fallback={<span />}>
                <Switch>
                  <Route
                    path="/camera/:id"
                    render={props => (
                      <Camera
                        {...props}
                        updateApp={this.updateComponent}
                      />
                    )}
                    exact
                  />
                </Switch>
              </Suspense>
              <CameraFooter updateApp={this.updateComponent} />
            </section>
          )}
          {screen === 'comparator' && (
            <section className="image-comparator">
              <Suspense fallback={<span />}>
                <Switch>
                  <Route
                    path="/comparator/:id"
                    render={props => (
                      <ImageComparator
                        {...props}
                        updateApp={this.updateComponent}
                      />
                    )}
                    exact
                  />
                </Switch>
              </Suspense>
            </section>
          )}
        </Router>
      </Fragment>
    );
  }
}

export default App;
