import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';

/**
 * Styles
 */
import 'normalize.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './assets/scss/main.scss';

/**
 * Store Configuration
 */
import configureStore from './store/configStore';
import initialState from './reducers/initialState';

const store = configureStore(initialState);

ReactDOM.render(
  <Provider store={store}>
    <Fragment>
      <App />
    </Fragment>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.register();
