import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from '../reducers';

/**
 * Configurantion of the devtools to be used just in development
 */
const enhancer = composeWithDevTools(
  applyMiddleware(thunk),
);

/**
 * creator function of the store with his configuration
 */
export default function configStore(initialState) {
  return createStore(rootReducer, initialState, enhancer);
}
